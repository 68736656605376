import { sva } from '@allenai/varnish-panda-runtime/css';
import type { RecipeVariantProps } from '@allenai/varnish-panda-runtime/types';

const avatarRecipe = sva({
    slots: ['root', 'image'],
    base: {
        root: {
            cursor: 'pointer',
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            margin: '[5px]',
            backgroundColor: 'colorPalette.fill',
            color: 'colorPalette.contrast',
        },
        image: {
            width: '[100%]',
            height: '[100%]',
            objectFit: 'cover',
            borderRadius: '[20%]',
        },
    },
    variants: {
        size: {
            small: {
                root: {
                    width: '[32px]',
                    height: '[32px]',
                },
            },
            medium: {
                root: {
                    width: '[40px]',
                    height: '[40px]',
                },
            },
            large: {
                root: {
                    width: '[56px]',
                    height: '[56px]',
                },
            },
        },
        color: {
            default: {
                root: {
                    colorPalette: 'elements.default',
                },
            },
            primary: {
                root: {
                    colorPalette: 'elements.primary',
                },
            },
            secondary: {
                root: {
                    colorPalette: 'elements.secondary',
                },
            },
            tertiary: {
                root: {
                    colorPalette: 'elements.tertiary',
                },
            },
        },
        shape: {
            square: {
                root: {
                    borderRadius: '[0]',
                },
            },
            circle: {
                root: {
                    borderRadius: '[50%]',
                },
            },
        },
        hasImage: {
            true: {
                root: {
                    padding: '[0]',
                },
            },
            false: {
                root: {
                    padding: '[4px]',
                },
            },
        },
    },
    defaultVariants: {
        size: 'medium',
        shape: 'square',
        hasImage: true,
        color: 'default',
    },
});

type AvatarRecipeProps = RecipeVariantProps<typeof avatarRecipe>;

export { avatarRecipe, avatarRecipe as default };
export type { AvatarRecipeProps };
